import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const Player = styled.div`
  max-width: 720px;
  max-height: 480px;
  min-width: 100%;
  height: 75vw;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    text-align: center;

    img {
      width: 10vh;
    }
  }
`;

export default function Live() {
  return (
    <Layout>
      <SEO title="Live" />
      <Player>
        <div>
          <img src="/images/kjell7.svg" alt="KJELL7" />
          <h2>We'll be right back!</h2>
        </div>
      </Player>
    </Layout>
  );
}
